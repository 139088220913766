import { fromJS } from 'immutable'
import { toSnakeCase } from 'common/util/string'

export const cyleraLogoBlue = '#037baf'
export const blueColor = '#04c0ff'
export const ceruleanColor = '#2a52be'
export const indigoColor = '#6610f2'
export const purpleColor = '#6f42c1'
export const pinkColor = '#e83e8c'
export const redColor = '#e65252'
export const orangeColor = '#ff9135'
export const yellowColor = '#ffe24f'
export const greenColor = '#92DB54'
export const tealColor = '#4bc0c0'
export const cyanColor = '#5bc0de'
export const whiteColor = '#fff'
export const grayColor = '#636c72'
export const blackColor = '#000000'
export const skyColor = '#bceeff'

export const fadedGrayColor = '#d8d8d8'

export const fadedBlueColor = 'rgba(4, 123, 248, 0.2)'
export const fadedRedColor = 'rgba(230, 82, 82, 0.2)'
export const fadedGreenColor = 'rgba(94, 179, 20, 0.2)'
export const fadedOrangeColor = 'rgba(253, 126, 20, 0.2)'

export const fadedBlueColor2 = 'rgba(4, 123, 248, 0.4)'
export const fadedRedColor2 = 'rgba(230, 82, 82, 0.4)'
export const fadedGreenColor2 = 'rgba(94, 179, 20, 0.4)'
export const fadedOrangeColor2 = 'rgba(253, 126, 20, 0.4)'

export const goldColor = '#ffcc00'

/* activity state colors */
export const activeColor = cyleraLogoBlue
export const idleColor = fadedGrayColor
export const offColor = grayColor
export const uptimeColor = greenColor

export const allColors = [
  blueColor,
  redColor,
  greenColor,
  orangeColor,
  tealColor,
  pinkColor,
  yellowColor,
  purpleColor,
  cyanColor
]
export const fadedColors = [
  fadedBlueColor,
  fadedRedColor,
  fadedGreenColor,
  fadedOrangeColor
]
export const fadedColors2 = [
  fadedBlueColor2,
  fadedRedColor2,
  fadedGreenColor2,
  fadedOrangeColor2
]

export const chartColors = {
  // Severity/criticality/risk
  info: tealColor,
  low: greenColor,
  medium: yellowColor,
  high: orangeColor,
  critical: redColor,
  // Class
  other: purpleColor,
  medical: cyleraLogoBlue,
  misc_iot: pinkColor,
  infrastructure: skyColor,
  unknown: grayColor
}

// Lowest/lightest first
export const heatMapColors = [
  '#d0ebff',
  '#83c0ed',
  '#408fc8',
  '#1f73b0',
  '#004475'
]

export const buttonColorClasses = [
  'btm-customLightBlue',
  'btn-customGreen',
  'btn-customOrange',
  'btn-customBlue',
  'btn-customPurple',
  'btn-customIndigo',
  'btn-customPink',
  'btn-customTeal'
]

export const fdaColorMap = fromJS({
  tag_fda_vendor_name: {
    other: 1,
    baxter: 2
  },
  tag_fda_product_code: {
    other: 1
  },
  tag_fda_specialty: {
    other: 1
  },
  tag_fda_subpart_name: {
    other: 1
  }
})

export const deviceColorMap = fromJS({
  tag_class: {
    other: 1,
    medical: 2,
    misc_iot: 3,
    infrastructure: 4
  },
  tag_type: {
    other: 1,
    'blood pump': 2,
    'infusion pump': 3,
    'heart monitor': 4,
    'co2 monitor': 5,
    elevator: 6,
    'x-ray': 7,
    ultrasound: 8,
    printer: 9,
    camera: 10,
    bed: 11,
    laptop: 12
  },
  tag_vendor: {
    other: 1,
    'ge healthcare': 2,
    phillips: 3,
    stryker: 4,
    dell: 5,
    siemens: 6
  },
  tag_model: {
    'PU v102': 1,
    'SP 17': 2,
    'GE 2000': 3,
    'Stryker v10': 4,
    'P 02 v1': 5,
    S4000: 6,
    'GE P40': 7,
    'SP IV2': 8,
    v200: 9,
    C450: 10,
    'inspiron 600': 11,
    'inspiron 750': 12,
    'P-BED 01': 13,
    'STB 12': 14
  },
  tag_organization_id: {
    Cylera: 1
  },
  ...fdaColorMap.toJS()
})

// Don't mark "low risk" alerts as green! https://app.clubhouse.io/cylera/story/1498/alerts-change-colors-so-low-isn-t-green
export const alertColorMap = fromJS({
  info: grayColor,
  low: ceruleanColor,
  medium: yellowColor,
  high: orangeColor,
  critical: redColor
})

export const lowMediumHighCriticalColorMap = fromJS({
  info: tealColor,
  low: greenColor,
  medium: yellowColor,
  high: orangeColor,
  critical: redColor
})

export const quarantineStateColorMap = fromJS({
  quarantine_pending: redColor,
  quarantine_in_progress: orangeColor,
  unquarantine_in_progress: orangeColor,
  quarantine_completed: tealColor,
  unquarantine_pending: greenColor,
  default: indigoColor
})

export const vulnerabilityStateColorMap = fromJS({
  vulnerable: redColor,
  not_vulnerable: blueColor
})

export const applyChartColors = (series, color = undefined) => {
  if (!series) {
    return null
  }
  return series.map(datum => {
    if (color) {
      return {
        ...datum,
        color: color
      }
    }
    const key = toSnakeCase(datum.label)
    if (key && key in chartColors) {
      return {
        ...datum,
        color: chartColors[key]
      }
    } else {
      return datum
    }
  })
}

export function getColorForDeviceTag(deviceTag, deviceTagValue) {
  if (deviceTagValue === 'unknown') {
    return grayColor
  }
  if (deviceTag === 'tag_model') {
    return blackColor
  }
  return allColors[deviceColorMap.get(deviceTag).get(deviceTagValue) - 1]
}

export function getColorForFDATag(tag, tagValue) {
  return buttonColorClasses[fdaColorMap.get(tag).get(tagValue) - 1]
}

export function getColorFoUserRole(userRole) {
  if (userRole === 'admin') {
    return greenColor
  }
  return redColor
}

export function getColorForValue(value, colorMap, defaultColor = blackColor) {
  return colorMap[value] || defaultColor
}
