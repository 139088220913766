import asyncComponent from 'common/components/core/AsyncComponent'

export const ROUTE_LOGIN = '/login'

export const bootstrapRoutes = [
  {
    route: ROUTE_LOGIN,
    component: asyncComponent(() => import('components/auth/Login'))
  }
]

export default bootstrapRoutes
