import { createStore, compose } from 'redux'

import { createBrowserHistory } from 'history'
import rootReducer from 'core/reducer'
import middlewares from '../middlewares'

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []

if (false && process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const extendedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const composedEnhancers = extendedCompose(middlewares, ...enhancers)

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
