import { combineReducers } from 'redux'

import organizations from './Organizations/reducer'
import users from './Users/reducer'
import welcome from './Welcome/reducer'

export default combineReducers({
  organizations,
  users,
  welcome
})
