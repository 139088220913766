import apiAction from 'common/actions/api'

const adminApi = apiAction('admin')

export const RESET_ORGANIZATION_PAGE = 'cylera/admin/RESET_ORGANIZATION_PAGE'

export const getOrganizationDetails = (orgId, callback) => {
  return adminApi(
    'organization',
    {
      id: orgId
    },
    {
      cacheable: false,
      success: callback
    }
  )
}

export const updateOrganizationDetails = (id, name, callback) => {
  return adminApi(
    'organization',
    {
      id,
      name
    },
    {
      method: 'PUT',
      cacheable: false,
      success: callback
    }
  )
}

export const deleteOrganization = (id, callback, failureCallback) => {
  return adminApi(
    'organization',
    {
      id
    },
    {
      method: 'DELETE',
      success: callback,
      failure: failureCallback,
      cacheable: false
    }
  )
}

export function resetOrganizationPage() {
  return dispatch => {
    dispatch({
      type: RESET_ORGANIZATION_PAGE
    })
  }
}
