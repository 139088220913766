import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'
import { RESET_ORGANIZATION_PAGE } from './actions'

const initialState = fromJS({
  organization: undefined,
  organization_loading: true
})

function organizationReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'admin', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_ORGANIZATION_PAGE:
      return state.merge({
        organization: initialState.get('organization'),
        organization_loading: initialState.get('organization_loading')
      })
    default:
      return state
  }
}

export default organizationReducer
