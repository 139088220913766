import { fromJS } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { RESET_ACCESS_WELCOME } from './actions'

const initialState = fromJS({
  ...apiInitialState('access_welcome')
})

function welcomeReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'admin', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_ACCESS_WELCOME:
      return state.merge({
        access_welcome: initialState.get('access_welcome')
      })

    default:
      return state
  }
}

export default welcomeReducer
