import apiAction from 'common/actions/api'

const adminApi = apiAction('admin')

export const RESET_ORGANIZATIONS_LIST = 'cylera/admin/RESET_ORGANIZATIONS_LIST'

export const getOrganizations = (page, pageSize, sortKey, sortDirection) => {
  return adminApi(
    'organizations',
    {
      page: page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection
    },
    {
      cacheable: false
    }
  )
}

export const addNewOrganization = (organization, callback) => {
  return adminApi(
    'organization',
    {
      name: organization.name,
      internal_name: organization.internal_name
    },
    {
      method: 'POST',
      success: callback,
      cacheable: false
    }
  )
}

export const getOrganizationDetails = (orgId, callback) => {
  return adminApi(
    'organization',
    {
      id: orgId
    },
    {
      cacheable: false,
      success: callback
    }
  )
}

// export const updateOrganizationDetails = (organization, callback) => {
//   return adminApi(
//     'organization',
//     {
//       id: organization.id,
//       name: organization.name
//     },
//     {
//       method: 'PUT',
//       cacheable: false,
//       success: callback
//     }
//   )
// }

export const deleteOrganization = (id, callback, failureCallback) => {
  return adminApi(
    'organization',
    {
      id
    },
    {
      method: 'DELETE',
      success: callback,
      failure: failureCallback,
      cacheable: false
    }
  )
}

export function resetOrganizationsList() {
  return dispatch => {
    dispatch({
      type: RESET_ORGANIZATIONS_LIST
    })
  }
}
