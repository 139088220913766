import ReactGA from 'react-ga'

import * as actions from '../actions/analytics'
import * as authSelectors from '../selectors/login'

const analyticsMiddleware = store => next => action => {
  if (action) {
    const state = store.getState()
    const isSystemUser = authSelectors.isSystemUser(state, {})

    if (isSystemUser) {
      // TODO: track using custom dimension/alternate tracking ID
      // https://github.com/react-ga/react-ga/issues/265
      return next(action)
    }
    if (action.type === actions.INIT_TRACKING) {
      const { testMode } = action
      const params = {
        testMode: testMode,
        titleCase: false
      }
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, params)
      store.dispatch(actions.trackPageView({ location: window.location }))
    }

    if (action.type === actions.TRACK_AGGREGATE_PAGE_VIEW) {
      const { id, location } = action
      const aggregatePath = location.pathname.replace(id, '[ID]')

      ReactGA.event({
        category: 'Aggregated Views',
        action: aggregatePath
      })
    }

    if (action.type === actions.TRACK_PAGE_VIEW) {
      const { hash, pathname, search } = action.location
      ReactGA.pageview(`${pathname}${hash}${search}`)
    }

    return next(action)
  }
}

export default analyticsMiddleware
