import { applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'

import history from 'core/store'

import analyticsMiddleware from 'common/middlewares/analyticsMiddleware'
import apiMiddleware from 'common/middlewares/apiMiddleware'
import historyMiddleware from 'common/middlewares/historyMiddleware'
import loggingMiddleware from 'common/middlewares/loggingMiddleware'
import notificationsMiddleware from 'common/middlewares/notificationsMiddleware'
import socketMiddleware from 'common/middlewares/socketMiddleware'

import lifecycleMiddleware from './lifecycleMiddleware'

export default applyMiddleware(
  analyticsMiddleware,
  apiMiddleware,
  historyMiddleware,
  lifecycleMiddleware,
  loggingMiddleware,
  notificationsMiddleware,
  routerMiddleware(history),
  socketMiddleware,
  thunk
)
