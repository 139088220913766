import { fromJS } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'

const initialState = fromJS({
  ...apiInitialState('user')
})

function usersReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'admin', initialState)
  if (apiResult) {
    return apiResult
  }

  return state
}

export default usersReducer
