import asyncComponent from 'common/components/core/AsyncComponent'

export const ROUTE_ORGANIZATIONS = '/organizations'

export const ROUTE_ORGANIZATION_DETAIL_BASE = '/organization/:id' // Base route to allow hard linkable modal
export const ROUTE_ORGANIZATION_DETAIL = '/organization/id'

export const ROUTE_ORGANIZATIONS_ACTION_BASE = // Base route allow hard linkable modal
  '/organizations/action/:organizationsAction'
export const ROUTE_ORGANIZATIONS_ACTION =
  '/organizations/action/organizationsAction'

export const adminRoutes = [
  {
    route: ROUTE_ORGANIZATIONS,
    component: asyncComponent(() =>
      import('components/main/Organizations/OrganizationsList')
    ),
    icon: 'os-icon-grid-squares',
    title: 'Admin',
    navbar: true,
    subContexts: [/admin\/(organization)(.*?)/]
  },
  {
    route: ROUTE_ORGANIZATIONS_ACTION_BASE,
    // modal: true,
    exact: true,
    parent: ROUTE_ORGANIZATIONS,
    component: asyncComponent(() =>
      import('components/main/Organizations/OrganizationsList')
    )
  },
  {
    route: ROUTE_ORGANIZATION_DETAIL_BASE,
    component: asyncComponent(() =>
      import('components/main/Organizations/OrganizationsList')
    ),
    exact: true,
    icon: 'os-icon-grid-squares',
    title: 'Admin'
  },
  {
    route: ROUTE_ORGANIZATIONS_ACTION,
    modal: true,
    parent: ROUTE_ORGANIZATIONS,
    component: asyncComponent(() =>
      import('components/main/Organizations/OrganizationDetail')
    )
  },
  {
    route: ROUTE_ORGANIZATION_DETAIL,
    component: asyncComponent(() =>
      import('components/main/Organizations/OrganizationDetail')
    ),
    modal: true,
    parent: ROUTE_ORGANIZATIONS
  }
]

export default adminRoutes
