import 'abortcontroller-polyfill'

export const API_REQUEST_FAILURE = 'core/API_REQUEST_FAILURE'
export const API_REQUEST_LOADING = 'core/API_REQUEST_LOADING'
export const API_REQUEST_SUCCESS = 'core/API_REQUEST_SUCCESS'
export const API_REQUEST_CALLBACK = 'core/API_REQUEST_CALLBACK'
export const API_REFETCH_FOR_ROUTE = 'core/API_REFETCH_FOR_ROUTE'
export const API_REGISTER_PARAM = 'core/API_REGISTER_PARAM'

const AbortController = window.AbortController
const defaultOptions = {
  cacheable: true,
  failure: undefined,
  method: 'GET',
  model: undefined,
  success: undefined
}

/*
  Creates API action namespace:
    const apiNamespace = apiAction('name')

  Which can then be used to create Redux API actions:
    const apiAction = params =>
      apiNamespace(
        path(:responseKey),
        params={},
        method='GET',
        callback=undefined
      )

  (Further documentation in README)
*/

const apiAction = (name, apiBase = undefined) => (
  apiPath,
  params = {},
  options = {}
) => {
  var responseKey = undefined
  if (apiPath.indexOf(':') !== -1) {
    responseKey = apiPath.substr(apiPath.indexOf(':') + 1)
    apiPath = apiPath.replace(`:${responseKey}`, '')
  }
  const key = (responseKey || apiPath).replace(/\//g, '_')
  const basePath = apiBase || `/${name}`
  const { cacheable, failure, method, model, success } = {
    ...defaultOptions,
    ...options
  }
  return apiActionNamespace(
    name,
    `${basePath}/${apiPath}`,
    key,
    params,
    method,
    success,
    failure,
    cacheable,
    model
  )
}

const apiActionNamespace = (
  namespace,
  apiPath,
  responseKey,
  params,
  method,
  success,
  failure,
  cacheable,
  model
) => {
  const actionSignature = {
    api: {
      cacheable: cacheable,
      failure: failure,
      method: method,
      namespace: namespace,
      params: params,
      path: apiPath,
      responseKey: responseKey,
      success: success,
      model: model,
      route: window.location.href
    }
  }
  const loadingAction = controller => ({
    type: `${API_REQUEST_LOADING}:${namespace}:${apiPath}`,
    controller: controller,
    ...actionSignature
  })
  return dispatch => {
    const controller = new AbortController()
    dispatch(loadingAction(controller))
  }
}

export const callbackAction = action => {
  const { callback, callbackName, callbackMessage, getState } = action
  return {
    type: `${API_REQUEST_CALLBACK}:${callbackName}`,
    callback,
    callbackMessage,
    getState
  }
}

export const registerApiParam = (requestKey, storePath) => ({
  type: API_REGISTER_PARAM,
  storePath,
  requestKey
})

export const refetchForRoute = route => ({
  type: API_REFETCH_FOR_ROUTE,
  route
})

export default apiAction
