import { fromJS } from 'immutable'

import { apiNamespaceReducer, apiInitialState } from 'common/reducers/api'
import { RESET_ORGANIZATIONS_LIST } from './actions'

const initialState = fromJS({
  ...apiInitialState('organizations')
})

function organizationsReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'admin', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case RESET_ORGANIZATIONS_LIST:
      return state.merge({
        organizations: initialState.get('organizations')
      })

    default:
      return state
  }
}

export default organizationsReducer
