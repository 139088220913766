import API from 'common/util/api'

import {
  apiActionCacheKey,
  apiActionType,
  extractPagingParams,
  getGlobalParamValues
} from 'common/util/api'
import { safeAccess } from 'common/util/iterable'
import { featureEnabled, FEATURE_API_ERROR_ALERT } from 'common/util/env'
import {
  API_REQUEST_FAILURE,
  API_REQUEST_LOADING,
  API_REQUEST_SUCCESS,
  API_REQUEST_CALLBACK,
  API_REFETCH_FOR_ROUTE,
  callbackAction
} from 'common/actions/api'
import { putModel } from 'common/actions/modelRegistry'

import { failureMessage } from 'common/actions/notifications'

const apiCacheDuration = process.env.REACT_APP_API_CACHE_DURATION_MS || 3600000

const executeRequest = (dispatch, request, controller, getState, cacheKey) => {
  const {
    failure: failureCallback,
    method,
    namespace,
    params,
    path,
    responseKey,
    success: successCallback
  } = request

  const failureAction = (message, unauthorized, failureCallback, getState) => ({
    callback: failureCallback,
    callbackName: `FAILURE:${namespace}:${path}`,
    callbackMessage: message,
    getState,
    data: message,
    type: `${API_REQUEST_FAILURE}:${namespace}:${path}`,
    api: request,
    unauthorized: unauthorized
  })

  const handleFailure = (message, status) => {
    if (status === 403) {
      dispatch(
        failureMessage(
          `You are unauthorized to access ${path}`,
          'Insufficient Permissions'
        )
      )
    } else if (featureEnabled(FEATURE_API_ERROR_ALERT)) {
      dispatch(failureMessage(`Error executing request ${path}`, 'API Error'))
    }
    dispatch(failureAction(message, false, failureCallback, getState))
  }

  const requestHandler =
    method === 'GET' ? API.withAuth().get : API.withAuth().mutate

  // Grab the latest values for any globally stored params
  const requestParams = {
    ...params,
    ...getGlobalParamValues(getState())
  }

  requestHandler(path, requestParams, controller.signal, undefined, method)
    .then(response => {
      if (response) {
        if (response.ok) {
          return response.json().then(json => {
            if (!(responseKey in json) && method === 'GET') {
              console.error(
                `${responseKey} not in response - are you trying to store this response in Redux? Response:`,
                json
              )
            }
            const response = json[responseKey]
            let successAction = {
              callback: successCallback,
              callbackName: `SUCCESS:${namespace}:${path}`,
              callbackMessage: json,
              getState,
              type: `${API_REQUEST_SUCCESS}:${namespace}:${path}`,
              data: response,
              api: request,
              cacheKey: cacheKey
            }
            dispatch({
              ...successAction,
              ...extractPagingParams(json)
            })
          })
        } else {
          window.r = response
          return response.json().then(json => {
            handleFailure(json['message'] || json['messages'], response.status)
          })
        }
      }
    })
    .catch(error => {
      if (!controller.signal.aborted) {
        console.error(error)
        handleFailure('Unknown error occured!', null)
      }
    })
}

export const apiMiddleware = store => next => action => {
  const nextAction = next(action)
  if (action.api || action.callback) {
    const actionType = apiActionType(action)
    switch (actionType) {
      case API_REQUEST_LOADING:
        const state = store.getState()
        const cacheKey = apiActionCacheKey(action, getGlobalParamValues(state))
        if (action.api.method === 'GET' && cacheKey in state.api.cached) {
          const cachedAction = state.api.cached[cacheKey]
          if (cachedAction.cached > Date.now() - apiCacheDuration) {
            store.dispatch(cachedAction)
            return nextAction
          }
        }
        executeRequest(
          store.dispatch,
          action.api,
          action.controller,
          store.getState,
          cacheKey
        )
        break
      case API_REQUEST_SUCCESS:
        if (action.api.model) {
          store.dispatch(putModel(action.api.model, action.data))
        }
        if (action.callback) {
          store.dispatch(callbackAction(action))
        }
        break
      case API_REQUEST_FAILURE:
        if (action.callback) {
          store.dispatch(callbackAction(action))
        }
        break
      case API_REQUEST_CALLBACK:
        const { callback, callbackMessage, getState } = action
        callback(callbackMessage, getState)
        break
      default:
        return false
    }
  }
  if (action.type === API_REFETCH_FOR_ROUTE) {
    const { route } = action
    const state = store.getState()
    const cached = safeAccess(state, ['api', 'cached'])
    const refetch = cached
      .valueSeq()
      .filter(cache => cache.getIn(['api', 'route']) === route)
      .toJS()

    for (var index in refetch) {
      const controller = new window.AbortController()
      const { api } = refetch[index]
      const { namespace, path } = api
      const loadingAction = {
        type: `${API_REQUEST_LOADING}:${namespace}:${path}`,
        controller,
        api
      }
      store.dispatch(loadingAction)
    }
  }
  return nextAction
}

export default apiMiddleware
