import { INIT_SOCKET, REGISTER_SOCKET } from 'common/actions/socket'

const initialState = {
  sockets: {}
}

export const socketReducer = (state = initialState, action) => {
  switch (action.type) {
    case INIT_SOCKET:
      return {
        ...state,
        sockets: {
          ...state.sockets,
          [action.channel]: {
            ...state.sockets[action.channel],
            listeners: action.listeners || []
          }
        }
      }
    case REGISTER_SOCKET:
      return {
        ...state,
        sockets: {
          ...state.sockets,
          [action.channel]: {
            ...state.sockets[action.channel],
            socket: action.socket
          }
        }
      }
    default:
      return state
  }
}

export default socketReducer
