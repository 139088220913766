import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import apiReducer from 'common/reducers/api'
import historyReducer from 'common/reducers/history'
import modalReducer from 'common/reducers/modal'
import notificationsReducer from 'common/reducers/notifications'
import socketReducer from 'common/reducers/socket'

import authReducer from 'components/auth/reducer'
import mainReducer from 'components/main/reducer'

export default combineReducers({
  api: apiReducer,
  auth: authReducer,
  history: historyReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  routing: routerReducer,
  socket: socketReducer,

  main: mainReducer
})
