import React from 'react'
import { fromJS, OrderedMap } from 'immutable'
import { Redirect } from 'react-router-dom'

import asyncComponent from 'common/components/core/AsyncComponent'

import authRoutes from './authRoutes'
import bootstrapRoutes from './bootstrapRoutes'
import organizationRoutes from './organizationRoutes'
import userRoutes from './userRoutes'

export const paths = fromJS([
  // Bootstrap routes
  ...authRoutes,
  ...bootstrapRoutes,
  ...organizationRoutes,
  ...userRoutes,

  // Root path
  {
    component: () => <Redirect to="/welcome" />,
    exact: true,
    route: '/'
  },

  // Not found
  {
    component: asyncComponent(() =>
      import('common/components/layout/NotFound')
    ),
    route: undefined,
    title: 'Not Found'
  }
])

export const routes = OrderedMap(paths.map(path => [path.get('route'), path]))

export default routes
