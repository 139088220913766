import {
  apiActionType,
  extractPagingParams,
  mergeExistingKeys
} from 'common/util/api'

import {
  API_REQUEST_FAILURE,
  API_REQUEST_LOADING,
  API_REQUEST_SUCCESS,
  API_REGISTER_PARAM
} from 'common/actions/api'

const initialState = {
  active: {},
  cached: {},
  params: {}
}

export const apiInitialState = key => ({
  [`${key}`]: undefined,
  [`${key}_error`]: false,
  [`${key}_failure`]: false,
  [`${key}_loading`]: true,
  [`${key}_page_size`]: undefined,
  [`${key}_total`]: undefined,
  [`${key}_unauthorized`]: false
})

export const apiReducer = (state = initialState, action) => {
  if (action.api) {
    const actionType = apiActionType(action)
    const activeRequestKey = `${action.api.namespace}:${action.api.path}`
    switch (actionType) {
      case API_REQUEST_FAILURE:
        delete state.active[activeRequestKey]
        return state
      case API_REQUEST_LOADING:
        if (activeRequestKey in state.active) {
          const controller = state.active[activeRequestKey]
          controller.abort()
        }
        const loadingState = {
          ...state,
          active: {
            ...state.active,
            [activeRequestKey]: action.controller
          }
        }
        // Clear cache for namespace if it's a POST request
        if (action.api.method === 'POST') {
          for (var cache in state.cached) {
            if (state.cached[cache].api.namespace === action.api.namespace) {
              delete state.cached[cache]
            }
          }
        }
        return loadingState
      case API_REQUEST_SUCCESS:
        if (!action.api.cacheable) {
          return state
        }
        const cacheKey = action.cacheKey
        delete state.active[activeRequestKey]
        const cachedAction = {
          ...action,
          cached: Date.now()
        }
        return {
          ...state,
          cached: {
            ...state.cached,
            [cacheKey]: cachedAction
          }
        }
      default:
        return false
    }
  }
  if (action.type === API_REGISTER_PARAM) {
    const { requestKey, storePath } = action
    return {
      ...state,
      params: {
        ...state.params,
        [requestKey]: storePath
      }
    }
  }
  return state
}

export const apiNamespaceReducer = (
  state,
  action,
  namespace,
  initialState = undefined
) => {
  if (action.api && action.api.namespace === namespace) {
    const key = action.api.responseKey
    const actionType = apiActionType(action)
    switch (actionType) {
      case API_REQUEST_FAILURE:
        return mergeExistingKeys(state, {
          [`${key}_error`]: action.data,
          [`${key}_loading`]: false,
          [`${key}_failure`]: true,
          [`${key}_unauthorized`]: action.unauthorized
        })
      case API_REQUEST_LOADING:
        return mergeExistingKeys(state, {
          [`${key}_loading`]: true,
          [`${key}_failure`]: false,
          [`${key}_unauthorized`]: undefined
        })
      case API_REQUEST_SUCCESS:
        const pagingParams = extractPagingParams(action, `${key}_`)
        return mergeExistingKeys(state, {
          [key]: action.data,
          [`${key}_loading`]: false,
          [`${key}_failure`]: false,
          [`${key}_unauthorized`]: false,
          ...pagingParams
        })
      default:
        return false
    }
  }
}

export default apiReducer
