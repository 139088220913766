export const FEATURE_DEMO_MODE = 'DEMO_MODE'
export const FEATURE_DEVICE_MAP = 'DEVICE_MAP'
export const FEATURE_ALERTS = 'ALERTS'
export const FEATURE_RISK = 'RISK'
export const FEATURE_DEVICE_OPERATIONAL = 'DEVICE_OPERATIONAL'
export const FEATURE_USER_ACTIONS = 'USER_ACTIONS'
export const FEATURE_API_ERROR_ALERT = 'API_ERROR_ALERT'
export const FEATURE_WEBSOCKET = 'WEBSOCKET'
export const FEATURE_POLICIES = 'POLICIES'
export const FEATURE_REPORTING = 'REPORTING'
export const FEATURE_THREATS = 'THREATS'
export const FEATURE_WORKFLOWS = 'WORKFLOWS'
export const FEATURE_RISK_IMPACTED_DEVICES = 'RISK_IMPACTED_DEVICES'
export const FEATURE_UTILIZATION = 'UTILIZATION'

export const featureEnabled = feature => {
  const featureFlag = `REACT_APP_FEATURE_ENABLED_${feature}`
  if (process.env[featureFlag] === '1') {
    return true
  }
  return false
}
