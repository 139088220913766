import io from 'socket.io-client'

import API from 'common/util/api'
import { featureEnabled, FEATURE_WEBSOCKET } from 'common/util/env'

import {
  HANDLE_SOCKET_DATA,
  INIT_SOCKET,
  handleSocketData,
  registerSocket
} from 'common/actions/socket'

export const socketMiddleware = store => next => action => {
  const nextAction = next(action)

  const { channel, data, type: actionType } = action

  const { dispatch } = store

  const state = store.getState()

  switch (actionType) {
    case INIT_SOCKET:
      if (featureEnabled(FEATURE_WEBSOCKET)) {
        const url = API.getWebsocketURL(channel)
        const socket = io(url)
        dispatch(registerSocket(channel, socket))
        socket.on('data', data => {
          dispatch(handleSocketData(channel, data))
        })
        const token = API.getToken()
        socket.on('connect', () => {
          socket.emit('identify', token)
        })
      }
      break
    case HANDLE_SOCKET_DATA:
      const {
        socket: { sockets }
      } = state
      if (channel in sockets) {
        sockets[channel].listeners.forEach(listener => listener(data))
      }
      break
    default:
      return false
  }
  return nextAction
}

export default socketMiddleware
