import { fromJS } from 'immutable'

import { apiNamespaceReducer } from 'common/reducers/api'

import { LOGIN_REMOVE_ERROR, LOGIN_FAILURE } from 'common/actions/login'

const initialState = fromJS({
  user: undefined,
  is_loading: false,
  error: undefined,
  login_test: true
})

function loginReducer(state = initialState, action) {
  const apiResult = apiNamespaceReducer(state, action, 'auth', initialState)
  if (apiResult) {
    return apiResult
  }

  switch (action.type) {
    case LOGIN_REMOVE_ERROR:
      return state.merge({
        error: undefined
      })
    case LOGIN_FAILURE:
      return state.merge({
        error: action.data
      })
    default:
      return state
  }
}

export default loginReducer
