import React from 'react'
import { Route, Switch } from 'react-router'

import * as LIFECYCLE_ACTIONS from 'common/actions/lifecycle'
import asyncComponent from 'common/components/core/AsyncComponent'

import store, { history } from 'core/store'

const AsyncLogin = asyncComponent(() => import('components/auth/Login'))
const AsyncMain = asyncComponent(() => import('components/main'))
const AsyncNotFound = asyncComponent(() =>
  import('common/components/layout/NotFound')
)

class App extends React.Component {
  componentDidMount = () => {
    store.dispatch(
      LIFECYCLE_ACTIONS.initApp({
        history
      })
    )
  }

  render = () => (
    <Switch key={this.props.hasUser}>
      <Route exact path="/login" component={AsyncLogin} />
      <Route path="/" component={AsyncMain} />
      <Route component={AsyncNotFound} />
    </Switch>
  )
}

export default App
