export const CLOSE_SOCKET = 'core/CLOSE_SOCKET'
export const HANDLE_SOCKET_DATA = 'core/HANDLE_SOCKET_DATA'
export const INIT_SOCKET = 'core/INIT_SOCKET'
export const REGISTER_SOCKET = 'core/REGISTER_SOCKET'
export const REGISTER_SOCKET_LISTENER = 'core/REGISTER_SOCKET_LISTENER'

export const closeSocket = channel => ({
  type: CLOSE_SOCKET,
  channel: channel
})

export const handleSocketData = (channel, data) => ({
  type: HANDLE_SOCKET_DATA,
  channel: channel,
  data: data
})

export const initSocket = (channel, listeners) => ({
  type: INIT_SOCKET,
  channel: channel,
  listeners: listeners
})

export const registerSocket = (channel, socket) => ({
  type: REGISTER_SOCKET,
  channel: channel,
  socket: socket
})

export const registerSocketListener = (channel, listener) => ({
  type: REGISTER_SOCKET_LISTENER,
  channel: channel,
  listener: listener
})
