import asyncComponent from 'common/components/core/AsyncComponent'

export const ROUTE_USERS = '/users'

export const ROUTE_USER_DETAIL_BASE = '/user/:uuid' // Base route to allow hard linkable modal
export const ROUTE_USER_DETAIL = '/user/uuid'

export const userRoutes = [
  {
    route: ROUTE_USERS,
    component: asyncComponent(() => import('components/main/Users/UsersList')),
    icon: 'os-icon-grid-squares',
    title: 'Users',
    navbar: true,
    subContexts: [/admin\/(organization)(.*?)/]
  },
  {
    route: ROUTE_USER_DETAIL_BASE,
    component: asyncComponent(() => import('components/main/Users/UsersList')),
    exact: true,
    title: 'User Details'
  },
  {
    route: ROUTE_USER_DETAIL,
    component: asyncComponent(() => import('components/main/Users/UserDetail')),
    modal: true,
    parent: ROUTE_USERS
  }
]

export default userRoutes
