export const MODEL_REGISTRY_INIT = 'core/MODEL_REGISTRY_INIT'
export const MODEL_REGISTRY_PUT = 'core/MODEL_REGISTRY_PUT'
export const MODEL_REGISTRY_REFRESH = 'core/MODEL_REGISTRY_REFRESH'
export const MODEL_REGISTRY_UPDATE = 'core/MODEL_REGISTRY_UPDATE'

export const initModelRegistry = useSockets => ({
  type: MODEL_REGISTRY_INIT,
  useSockets: useSockets
})

export const putModel = (model, data) => ({
  type: MODEL_REGISTRY_PUT,
  model,
  data
})

export const refreshModel = (modelRef, uuid) => ({
  type: MODEL_REGISTRY_REFRESH,
  modelRef,
  uuid
})

export const updateModel = (modelRef, uuid, data) => ({
  type: MODEL_REGISTRY_UPDATE,
  modelRef,
  uuid,
  data
})
