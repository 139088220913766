import * as ANALYTICS_ACTIONS from 'common/actions/analytics'
import * as HISTORY_ACTIONS from 'common/actions/history'
import * as LIFECYCLE_ACTIONS from 'common/actions/lifecycle'

const lifecycleMiddleware = store => next => action => {
  let result = next(action)
  const { history } = action.params || {}

  switch (action.type) {
    case LIFECYCLE_ACTIONS.INIT_APP:
      store.dispatch(ANALYTICS_ACTIONS.initTracking())
      store.dispatch(
        HISTORY_ACTIONS.initListeners({
          history,
          listeners: [ANALYTICS_ACTIONS.trackPageView]
        })
      )
      break
    default:
      return result
  }
}

export default lifecycleMiddleware
