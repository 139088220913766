import { createMuiTheme } from '@material-ui/core/styles'

import { cyleraLogoBlue } from './colors'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: cyleraLogoBlue
    }
  },
  TextField: {
    height: '38px'
  }
})

export default theme
