import asyncComponent from 'common/components/core/AsyncComponent'

export const ROUTE_WELCOME = '/welcome'

export const bootstrapRoutes = [
  {
    route: ROUTE_WELCOME,
    component: asyncComponent(() => import('components/main/Welcome'))
  }
]

export default bootstrapRoutes
