import apiAction from 'common/actions/api'

import API from 'common/util/api'

export const LOGIN_FAILURE = 'cylera/auth/LOGIN_FAILURE'
export const LOGIN_REMOVE_ERROR = 'cylera/auth/LOGIN_REMOVE_ERROR'

const authApi = apiAction('auth')

export const doLogin = (email, password, successCallback, failureCallback) =>
  authApi(
    'login_user',
    {
      email: email,
      password: password
    },
    {
      method: 'POST',
      failure: failureCallback,
      success: response => {
        API.setToken(response.token)
        successCallback()
      }
    }
  )

export const doLogout = successCallback =>
  authApi(
    'logout_user',
    {},
    {
      method: 'POST',
      success: () => {
        if (successCallback) {
          successCallback()
        }
      }
    }
  )

export const getUser = () => authApi('get_user:user')

export const loginFailure = message => ({
  type: LOGIN_FAILURE,
  data: message
})

export const removeLoginError = () => ({
  type: LOGIN_REMOVE_ERROR
})
