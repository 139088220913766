import apiAction from 'common/actions/api'

export const RESET_ACCESS_WELCOME = 'cylera/admin/RESET_ACCESS_WELCOME'

const adminApi = apiAction('admin')

export const getHasAccessWelcome = (success, failure) => {
  return adminApi(
    'access/welcome',
    {},
    {
      cacheable: false,
      success,
      failure
    }
  )
}

export function resetAccessWelcome() {
  return dispatch => {
    dispatch({
      type: RESET_ACCESS_WELCOME
    })
  }
}
